.card {
  &-guides {
    @include card-design;
    width: 13rem;
    height: 13rem;
    padding: .5rem;
    margin: 1rem auto;
    @include media-breakpoint-up(md) {
      margin: 2rem .5rem;
    }
    @include media-breakpoint-up(lg) {
      margin: 2rem;
      width: 15rem;
      height: 15rem;
    }
    a {
      color: $SteelBlue;
    }
  }
  &-about {
    @include card-design;
    font-weight: 300;
    padding: 2rem;
  }
}
