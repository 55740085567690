//
// Custom Variables
//
// Here you can add your own variables overrides
//
// The file is included before any other variable file,
// so you can override them because they all use !default
// --------------------------------------------------

// For example, changing the primary color
// $primary: #3b5998;

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1600px,
) !default;
$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1520px
) !default;


$space-boxed:               1660px !default;

// Override Bootstrap
$font-family-sans-serif:      "Nunito Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-base:            $font-family-sans-serif !default;

$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge((
    6: ($spacer * 4.5),
    7: ($spacer * 6),
    8: ($spacer * 9),
    9: ($spacer * 12),
    10: ($spacer * 18)
), $spacers);


$PossumGrey: #4f4f4f;
$Matterhorn: #4b4b4b;
$Charcoal: #474747;
$DimGrey: #676767;
$SonicSilver: #757575;
$ClassicGrey: #828282;
$SuvaGrey: #919191;
$DarkGray: #A8A8A8;
$Silver: #C5C5C5;
$HexGrey: #fafafa;
$AliceBlue: #f4f8fa;
$AliceBlueType2: #e7f1f8;


$MinionYellow: #f5df4d;
$Chartreuse: #c1a909;

$YaleBlue: #234a80;
$Pantone: #00589b;
$TangBlue: #0059CF;
$Normay: #5b97b8;
$SteelBlue: #528ebb;
$BostonBlue: #3d82af;
$Sail: #a6d5f0;


$DarkRed: #9a0e0e;
$MantisColor: #65c563;
$AppleColor: #46b744;


