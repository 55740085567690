.latest-article {
  &-heading {
    color: $ClassicGrey;
    font-size: 1.2rem;
    @include media-breakpoint-up(md) {
      font-size: 2rem;
    }
    @include media-breakpoint-up(xl) {
      font-size: 2.75rem;
    }
    span {
      background: $Normay;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.4);
      border-radius: 31px;
      vertical-align: middle;
      font-weight: 300;
      line-height: 1rem;
      padding: 0 0.7rem;
      margin-left: .4rem;
      @include footer-font-size-big;
    }
  }
  &-card {
    margin-right: -15px;
    padding-top: 3rem;
    padding-bottom: 2rem;
    @include media-breakpoint-up(lg) {
      margin-right: -2.5rem;
      padding-top: 3rem;
    }
    &-wrap {
      padding-right: 0;
      padding-bottom: 0;
      @include media-breakpoint-up(lg) {
        padding-right: 1.5rem;
        padding-bottom: .5rem;
      }
      @include media-breakpoint-up(xl) {
        padding-right: 1.5rem;
        padding-bottom: .5rem;
      }
      .card {
        border: none;
        box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.15);
        border-radius: 1rem;
        font-size: .8rem;
        @include media-breakpoint-up(lg) {
          font-size: 1rem;
        }
        .card-title {
          height: 1.8rem;
          overflow: hidden;
          font-weight: 500;
          @include footer-font-size-big;
          margin-bottom: .5rem;
          @include media-breakpoint-up(lg) {
            margin-bottom: 1rem;
          }
        }
        .card-text {
          height: 2.7rem;
          overflow: hidden;
          font-weight: 300;
          color: $ClassicGrey;
          font-size: .7rem;
          line-height: 1.3rem;
          margin-bottom: .5rem;
          @include media-breakpoint-up(lg) {
            width: 75%;
            font-size: .9rem;
            margin-bottom: 1rem;
          }
        }
        a {
          @include link-unclassic;
          font-size: 1rem;
          padding: 0.175rem 0;
          &:hover,
          &:active,
          &:focus {
            outline: none;
            box-shadow: none;
          }
        }
      }
    }
  }
  &-link {
    @include link-classic();
  }
}
.article-spinner {
  width: 200px;
  height: 200px;
  color: $Normay;
}
