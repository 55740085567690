.header-common {
  height: auto;
  background: rgba($Pantone, 0.75);
  backdrop-filter: blur(14px);
  @include media-breakpoint-up(lg) {
    height: 600px;
  }
  @include media-breakpoint-up(xl) {
    height: 700px;
  }
}

.navbar-main-wrap {
  @include media-breakpoint-down(lg) {
    overflow: hidden;
  }
  .navbar-main {
    background: rgba(82, 142, 187, 0.6);
    border-bottom: 1px solid $white;
    backdrop-filter: blur(34px);
    padding: .5rem 0;
    color: white;
    @include media-breakpoint-up(xl) {
      padding: 1rem 0;
    }
    .header-dropdown-btn {
      .dropdown-toggle {
        @include yellow-button;
        font-size: 1.25rem;
        padding: .2rem .5rem;
        margin: .2rem 0 0;
        @media (min-width: 1200px) {
          font-size: 1.375rem;
          padding: .2rem 1.2rem;
        }
        &:after {
          display: none;
        }
      }
    }
    &-header {
      color: $DimGrey;
      padding: 1rem 0;
      margin: 0;

    }
    .navbar-main-mobile-link {
      font-size: 1.2rem;
      color: $BostonBlue;
      span {
        text-transform: lowercase;
      }
    }
    &.show {
      @include media-breakpoint-down(md) {
        text-align: center;
        padding-bottom: 0;
        &:after {
          @include pseudo;
          top: 58px;
          width: 100%;
          height: calc(100% - 52px);
          left: 0;
          z-index: -1;
          background: $white;
          @include media-breakpoint-up(sm) {
            top: 76px;
            height: calc(100% - 80px);
          }
        }
        .navbar-main-link {
          color: $DimGrey;
          padding: 1rem 0;
          &:hover,
          &:active,
          &:hover {
            color: $Matterhorn;
          }
        }
        .navbar-collapse {
          padding: 1.5rem 0 0;
        }
        .navbar-nav {
          & + .navbar-nav {
            position: relative;
            background: $HexGrey;
            padding: .3rem 0;

            &:after {
              @include pseudo;
              top: 0;
              width: 100%;
              height: 100%;
              left: -100%;
              background: $HexGrey;
            }

            &:before {
              @include pseudo;
              top: 0;
              width: 100%;
              height: 100%;
              right: -100%;
              background: $HexGrey;
            }

            .navbar-main-link {
              font-size: .9rem;
            }
          }
        }
        .navbar-burger {
          .navbar-burger-lines {
            visibility: hidden;
          }

          .navbar-close {
            visibility: visible;
          }
        }
      }
    }
    .navbar-main-link {
      font-size: 1rem;
      color: $white;
      font-weight: 300;
      padding-left: 3rem;
      padding-right: 3rem;
      &:hover,
      &:focus,
      &:active {
        color: white;
        text-decoration: underline;
      }
      @include media-breakpoint-up(lg) {
        font-size: 1.25rem;
      }
      @include media-breakpoint-up(xl) {
        font-size: 1.35rem;
      }
      &.active {
        color: $DimGrey;
        text-decoration: underline;
        @include media-breakpoint-up(lg) {
          color: $white;
          text-decoration: underline;
        }
      }
    }
    .navbar-brand {
      padding: 0;
      margin: 0;
      img {
        width: 37px;
        @include media-breakpoint-between(sm, xl) {
          width: 60px;
          height: 60px;
        }
        @include media-breakpoint-up(xl) {
          width: auto;
          height: auto;
        }
      }
    }
    .dropdown {
      text-align: center;
      .dropdown-menu {
        left: inherit;
        right: 0;
        top: 3.5rem;
        padding: .5rem 0;
        a {
          font-size: 1rem;
          font-weight: 300;
          padding: .3rem 3rem .3rem 1rem;
          @include media-breakpoint-between(sm, md) {
            font-size: 1.2rem;
          }
          @include media-breakpoint-up(md) {
            font-size: 1.375rem;
          }
        }
      }
    }
    .navbar-burger {
      position: relative;
      min-width: 22px;
      .navbar-close {
        color: white;
        font-size: 2rem;
        visibility: hidden;
        position: relative;
        left: .6rem;
      }
      .navbar-burger-lines {
        @include pseudo;
        @include pseudo-burger;
        width: 22px;
        right: 0;
        top: .4rem;
        @include media-breakpoint-down(md) {
          visibility: visible;
        }
        &::after {
          @include pseudo;
          @include pseudo-burger;
          top: 8px;
          width: 15px;
        }

        &::before {
          @include pseudo;
          @include pseudo-burger;
          top: 17px;
          width: 10px;
        }
      }
    }
    .navbar-toggler {
      border: none;
      background: none;
      align-items: center;
      padding: .25rem 0;
      @include media-breakpoint-down(md) {
        display: flex;
      }
    }
  }
}
.navbar-main-container {
  @include restore-container-padding();
}

.header-banner {
  position: relative;
  padding: 8rem 0 3rem;
  @include media-breakpoint-up(xl) {
    padding: 4.8rem 0 0;
  }
  @include media-breakpoint-up(xl) {
    padding: 7.5rem 0 0;
  }
  &-women {
    overflow: hidden;
    width: 100%;
    &-wrap {
      margin-bottom: -2.5rem;
    }
    img {
      max-width: 100%;
      @include media-breakpoint-down(lg) {
        max-width: 80%;
      }
    }
  }
  .jumbotron {
    background: none;
    line-height: 1.2rem;
    font-size: .8rem;
    font-weight: 300;
    text-align: center;
    padding: 0;
    margin: 0;
    @include media-breakpoint-up(lg) {
      text-align: left;
      font-size: 1.3rem;
      line-height: 1.7rem;
    }
    h1 {
      font-size: 1.6rem;
      font-weight: 300;
      line-height: 2rem;
      margin-bottom: .5rem;
      @include media-breakpoint-up(md) {
        font-size: 2rem;
        line-height: 2.5rem;
        margin-bottom: 1rem;
      }
      @include media-breakpoint-up(lg) {
        font-size: 2.5rem;
        line-height: 3.2rem;
      }
      @include media-breakpoint-up(xl) {
        font-size: 3rem;
        line-height: 3.7rem;
      }
      @include media-breakpoint-up(xxl) {
        font-size: 3.4rem;
        line-height: 4rem;
      }
    }
    .slogan{
      color: $MinionYellow;
      font-size: $font-size-base;
      @include media-breakpoint-up(lg) {
        font-size: 1.7rem;
      }
      &-description {
        position: relative;
        overflow: hidden;
        margin: 0 0 2rem;
        @include media-breakpoint-up(sm) {
          font-size: $font-size-base;
          line-height: 1.3rem;
        }
        @include media-breakpoint-up(md) {
          background: none;
          width: auto;
          &:after {
            display: none;
          }
        }
        @include media-breakpoint-up(lg) {
          margin: 3rem 0;
          font-size: 1.25rem;
          line-height: 1.7rem;
        }
        @include media-breakpoint-up(xl) {
          font-size: 1.25rem;
          line-height: 1.8rem;
        }
      }
    }
    .btn {
      @include classic-button-big;
      &.button-yellow-big {
        @include yellow-button;
        @include media-breakpoint-up(lg) {
          margin-right: 2rem;
        }
      }
      &.button-link-blue-transparent {
        @include white-transparent-button;
      }
    }
  }
}


