.diamond-right1 {
  right: 23%;
  top: 0;
  opacity:0.26;
  transform: rotate(-138.93deg);
}
.diamond-right2 {
  right: 14%;
  top: 8%;
  transform: rotate(125deg);
  opacity:0.88;
}
.diamond-right3 {
  right: 0;
  top: 18%;
  opacity:0.28;
  transform: rotate(-20deg);
}
.diamond-left1 {
  left: 20%;
  top: 16%;
  transform: rotate(20deg);
}
.diamond-left2 {
  left: 23%;
  top: 33%;
  transform: rotate(200deg);
  opacity: 0.73;
}
.triangle-middle-left {
  left: 15%;
  bottom: 35%;
  opacity: 0.7;
}
.parallax-wrap {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.diamond-bottom1 {
  left: 45%;
  bottom: 36%;
  transform: rotate(140deg);
  opacity: 0.8;
}
.diamond-bottom2 {
  right: 30%;
  bottom: 29%;
  opacity: 0.6;
}
.diamond-bottom3-out {
  right: -3%;
  bottom: 25%;
  opacity: 0.5;
  transform: rotate(260deg);
}
.diamond-bottom4 {
  left: 15%;
  bottom: 16%;
  transform: rotate(120deg);
}
.triangle-bottom1 {
  right: 40%;
  bottom: 14%;
  transform: rotate(160deg);
}
.diamond-bottom5 {
  right: 40%;
  bottom: 3%;
  opacity:.3;
  transform: rotate(20deg);
}
.diamond-bottom6 {
  right: 13%;
  bottom: 8%;
  opacity:.7;
  transform: rotate(20deg);
}
.blue-triangle-left {
  right: -700px;
  @include media-breakpoint-up(sm) {
    right: -103%;
  }
  @include media-breakpoint-up(md) {
    right: -73%;
  }
  @include media-breakpoint-up(lg) {
    right: -600px;
    bottom: -20%;
  }
  @include media-breakpoint-up(xl) {
    right: -470px;
  }
}
.blue-diamond-left1 {
  left: 5%;
  top: 5%;
  transform: rotate(-20deg);
}
.blue-diamond-left2 {
  left: -4%;
  bottom: 10%;
}

.blue-diamond-left3 {
  left: 10%;
  bottom: 8%;
  display: none;
  @include media-breakpoint-up(xxl) {
    display: block;
  }
}
