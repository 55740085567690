.pricing-page {
  @include footer-font-size-big;
  color: $ClassicGrey;
  padding: 7rem 0;
}

.pricing-subscription {
  .btn {
    &.btn-white {
      @include white-button;
      padding: .2rem 1rem;
      font-size: .9rem;
      box-shadow: 0px 1.06689px 2.13379px rgba(0, 0, 0, 0.25);
      border-radius: 8.53514px;
      border: 1px solid $YaleBlue;
      color: $YaleBlue;
      &:hover,
      &:focus,
      &:active {
        border: 1px solid $YaleBlue;
        background: $Sail;
        color: $YaleBlue;
        box-shadow: none;
        text-decoration: none;
      }
      &:disabled {
        color: $DimGrey;
        background: none;
        box-shadow: none;
        border: 1px solid $YaleBlue;
        text-decoration: none;
        &:hover,
        &:focus,
        &:active{
          color: $DimGrey;
          background: none;
          box-shadow: none;
          border: 1px solid $YaleBlue;
          text-decoration: none;
        }
      }
    }
  }
  .btn {
    &.btn-blue {
      @include white-button;
      background: $Sail;
      padding: .2rem 1rem;
      font-size: .9rem;
      box-shadow: 0px 1.06689px 2.13379px rgba(0, 0, 0, 0.25);
      border-radius: 8.53514px;
      border: 1px solid $YaleBlue;
      color: $YaleBlue;
      &:hover,
      &:focus,
      &:active {
        border: 1px solid $YaleBlue;
        background: white;
        color: $YaleBlue;
        text-decoration: none;
      }
    }
  }
  &-mobile{
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 2rem;
    color: $Charcoal;
    .recommended-color {
      color: $Pantone;
    }
    .free-color {
      color: $SuvaGrey;
    }
    .pricing-table {
      border-radius: .8rem;
      filter: drop-shadow(0px 0px 5.59129px rgba(0, 0, 0, 0.3));
      overflow: hidden;
      color: $SonicSilver;
    }
  }
  .pricing-table {
    & > div {
      @include footer-font-size-big;
      background: $AliceBlueType2;
      &:nth-child(2n) {
        background: $white;
      }
      > div {
        padding: 1rem;
      }
      .column-header-red {
        color: $DarkRed;
      }
      .unlimited {
        text-transform: uppercase;
        font-weight: 900;
        background: linear-gradient(to bottom, #1B01BE, #0057FF, #69AEFF 70%, white);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .pricing-check,
      .pricing-close {
        font-size: 2rem;
      }
      .pricing-check {
        color: $AppleColor;
      }
      .pricing-close {
        color: $DarkRed;
      }
    }
  }
  &-desktop {
    .bit-price {
      padding-top: 2rem;
      padding-bottom: 2rem;
      &-name {
        font-size: 1.8rem;
        color: $Charcoal;
      }
      &-dollars {
        font-size: 1.8rem;
        color: $TangBlue;
        margin-top: .5rem;
        margin-bottom: .5rem;
        line-height: 4rem;
        &.grey-color {
          color:  $SuvaGrey;
        }
      }
      .btn {
        font-size: 1.375rem;
      }
      &.blue {
        background: $DimGrey;
        box-shadow: 0px 2.42307px 2.42307px rgba(0, 0, 0, 0.25);
        border-radius: 11.1414px;
        background: linear-gradient(110.76deg, $Normay -1.67%, $Pantone 109.68%);
        position: relative;
        overflow: hidden;
        z-index: 1;
        &:after {
          @include pseudo();
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          background-image: url('../media/various/price_rectangle.png');
          background-repeat: no-repeat;
          z-index: -1;
        }
        .most-popular {
          font-size: 1rem;
          font-weight: 900;
        }
        * {
          color: $white;
        }
        .btn {
          color: $YaleBlue;
        }
      }
    }
    .section-header {
      color:$DarkGray;
      text-transform: uppercase;
      font-size: 1.3rem;
    }
    .pricing-table {
      border-radius: .8rem;
      filter: drop-shadow(0px 0px 5.59129px rgba(0, 0, 0, 0.3));
      overflow: hidden;
      color: $SonicSilver;
      > .container {
        background: $AliceBlueType2;
        &:nth-child(2n) {
          background: $white;
        }
      }
      & > div {
        .pricing-check,
        .pricing-close {
          font-size: 3rem;
        }
      }
    }
  }
}
.popover-circle {
  font-style: normal;
  font-size: 1.2rem;
  @include media-breakpoint-up(lg) {
    font-size: 1.5rem;
  }
}
.popover-dark {
  font-style: normal;
  z-index: 1;
  text-align: left;
  .tooltip-inner {
    background: $Charcoal;
    text-align: left;
    padding: .5rem 1rem;
  }
  .arrow {
    &:before {
      border-top-color: $Charcoal;
      top: -1px;
    }
  }
}


