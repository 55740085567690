.footer-common{
  background:$PossumGrey;
  font-weight: 300;
  padding-top: 12rem;
  padding-bottom: 3rem;
  @include media-breakpoint-up(lg) {
    padding-top: 12rem;
  }
  .copyright {
    font-weight: 300;
    @include footer-font-size-small;
    a {
      color: white;
      @include footer-font-size-small;
      &:hover,
      &:focus {
        color: white;
        text-decoration: underline;
      }
    }
  }
  .logo-slogan {
    margin-bottom: 1rem;
    font-size: .8rem;
    @include media-breakpoint-up(lg) {
      font-size: 1.06rem;
    }
    img {
      margin-right: .8rem;
      @include media-breakpoint-down(xl) {
        max-width: 40px;
      }
    }
  }
  address {
    margin: .6rem 0 0;
  }
  h5 {
    margin: 0 0 .5rem;
    font-weight: 700;
    @include footer-font-size-big;
    @include media-breakpoint-down(md) {
      margin-top: 2rem;
    }
  }
  .footer-address {
    @include footer-font-size-small;
    text-align: left;
    @include media-breakpoint-up(md) {
      text-align: right;
    }
  }
  &-contacts {
    @include footer-font-size-big;
    a {
      @include footer-font-size-big;
    }
  }
  &-list{
    margin-bottom: .3rem;
    &:last-child {
      margin-bottom:0;
    }
    &-link {
      @include footer-font-size-big;
      font-weight: 300;
      color: white;
      padding: 0;
      display: inline;
      &:hover,
      &:focus {
        color: white;
        text-decoration: underline;
      }
    }
  }
  &-lines {
    position: absolute;
    width: 100%;
    height: 100%;
    display:none;
    @include media-breakpoint-up(lg) {
      display:block;
    }
    &:before,
    &:after {
      @include pseudo;
      height: 100%;
      width: 3px;
      border-right: 1px solid $SuvaGrey;
    }
    &:before {
      left: 0;
      @include media-breakpoint-between(md, lg) {
        left: -10%;
      }
    }
    &:after {
      right: 0;
      @include media-breakpoint-between(md, lg) {
        right: -10%;
      }
    }
  }
}
.reach-out {
  background: linear-gradient(110.76deg, $Normay -1.67%, $Pantone 109.68%);
  box-shadow: 0px 0px 8px rgba(black, 0.4);
  margin-top: -5rem;
  position: relative;
  bottom:-5rem;
  display: flex;
  text-align: center;
  padding: 3rem 0;
  border-radius: 0;
  @include media-breakpoint-up(sm) {
    border-radius: 1.5rem;
  }
  @include media-breakpoint-up(lg) {
    text-align: left;
  }
  p {
    @include footer-font-size-big;
    font-weight: 300;
    &:last-child {
      margin: 0;
    }
  }
  .btn {
    @include classic-button-big;
    font-size: 1rem;
    line-height: 1.7rem;
    @include media-breakpoint-up(lg) {
      display: inline-block;
      min-width: 16rem;
    }
    &.button-yellow-big {
      @include yellow-button;
      margin: 1rem 0;
      @include media-breakpoint-up(lg) {
        margin: 0;
      }
    }
    &.button-link-blue-transparent {
      @include white-transparent-button;
      margin: 0;
    }
  }
}
