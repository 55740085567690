.main-page-blue-section {
  color: white;
  min-height: 400px;
  z-index: 1;
  font-size: 1rem;
  overflow: hidden;
  @include media-breakpoint-up(xl) {
    font-size: 1.5rem;
  }
  /*&::after {
    @include pseudo;
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;
    z-index: -1;
    !*background-image: url('../media/various/floating_shape1.png'), url('../media/various/floating_shape2.png');
    background-repeat: no-repeat, no-repeat;
    background-position: left bottom, right bottom;*!
  }
  &::before {
    @include pseudo;
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;
    z-index: -1;
   !* background-image: url('../media/various/floating_shape3.png'), url('../media/various/floating_shape4.png');
    background-repeat: no-repeat, no-repeat;
    background-position: 100px 50px, 150px bottom;*!
  }*/
  .card-space {
    margin-top: 3rem;
    margin-bottom: 1rem;
  }
  &-summary {
    font-weight: 300;
    margin: 0 auto;
    @include media-breakpoint-up(lg) {
      width: 50%;
    }
    b {
      font-weight: 600;
    }
  }
}
.blur-card-blue {
  min-height: 16rem;
  color: white;
  font-size: 1.1rem;
  font-weight: 300;
  line-height: 1.5rem;
  max-width: 250px;
  margin: 0 auto 2rem;
  @include media-breakpoint-up(lg) {
    font-size: 1rem;
    line-height: 1.8rem;
    max-width: none;
    margin: 0 0 2rem;
  }
  @include media-breakpoint-up(xxl) {
    font-size: 1.3rem;
    line-height: 1.8rem;
  }
  .card{
    background: none;
    border: none;
    &-body {
      text-align: center;
      position: relative;
      z-index: 1;
      &:after {
        @include pseudo;
        background: none;
        background: linear-gradient(90.58deg, rgba(255, 255, 255, 0.464) 15.58%, rgba(255, 255, 255, 0.056) 154.58%);
        box-shadow: 0px 1.46485px 35.1563px rgba(0, 0, 0, 0.1);
        border-radius: 51.2697px;
        backdrop-filter: blur(29.297px);
        border: 2px solid rgba(255,255,255,0.3);
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index:-1;
      }
    }
    &-title {
      font-size: 1.1rem;
      font-weight: bold;
      @include media-breakpoint-up(lg) {
        font-size: 1.2rem;
      }
      @include media-breakpoint-up(xxl) {
        font-size: 1.5rem;
      }
      font-weight: 400;
      span {
        display: inline;
        @include media-breakpoint-up(xxl) {
          display: none;
        }
      }
    }
    &-text {
      overflow:hidden;
      height: 60px;
    }
  }
  &-icon {
    font-size: 3rem;
    margin: 1rem auto;
    height: 60px;
    display: block;
  }
}
