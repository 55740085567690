.nav-tabs {
  border: none;
  border-left: 2px solid $Silver;
  justify-content: center;
  display: block;
  @include media-breakpoint-up(lg) {
    border: none;
    display: flex;
  }
  .nav-link {
    border: none;
    font-size: 1.2rem;
    font-weight: 300;
    margin: 0 2rem;
    padding: .5rem 0;
    display: block;
    position: relative;
    color: $Matterhorn;
    @include media-breakpoint-up(lg) {
      display: inherit;
      border: none;
      padding: 0;
      color: $SteelBlue;
      font-size: 1.75rem;
    }

    &.active {
      font-weight: 600;
      color: $Matterhorn;
      border-bottom: none;
      background: none;
      &:after {
        @include pseudo();
        @include media-breakpoint-up(lg) {
          display: none;
        }
        width: 2px;
        height: 100%;
        top: 0;
        left: calc(-2rem - 2px);
        background: $SuvaGrey;
      }
      @include media-breakpoint-up(lg) {
        border-bottom: 1px dashed;
        color: $SteelBlue;
      }
    }
  }
}
.tab-content {
  font-weight: 300;
  font-size: 1rem;
  min-height: 500px;
  @include media-breakpoint-up(lg) {
    font-size: 1.25rem;
  }
}
