@mixin pseudo() {
  display: block;
  content: "";
  position: absolute;
}

@mixin restore-container-padding() {
  padding-left: ($grid-gutter-width / 2) !important;
  padding-right: ($grid-gutter-width / 2) !important;
}

@mixin pseudo-burger() {
  height: 3px;
  right: 0;
  background: $white;
  @include border-radius(100%);
}
@mixin font-size-pattern() {
  font-size: 1rem;
  line-height: 1.4rem;
  @include media-breakpoint-up(lg) {
    font-size: 1.25rem;
    line-height: 1.7rem;
  }
  @include media-breakpoint-up(xl) {
    font-size: 1.375rem;
    line-height: 1.8rem;
  }
}
@mixin footer-font-size-small() {
  font-size: .7rem;
  line-height: 1.3rem;
  @include media-breakpoint-up(xl) {
    font-size: 1.06rem;
    line-height: 2rem;
  }
}
@mixin footer-font-size-big() {
  font-size: .8rem;
  line-height: 1.5rem;
  @include media-breakpoint-up(lg) {
    font-size: 1rem;
    line-height: 1.8rem;
  }
  @include media-breakpoint-up(xxl) {
    font-size: 1.25rem;
    line-height: 2rem;
  }
}
@mixin classic-button-big() {
  border-radius: 0.8rem;
  font-weight: normal;
  padding: .7rem 2rem;
  display: block;
  width: 100%;
  margin: 0 0 1rem;
  min-width: 13rem;
  @include font-size-pattern;
  text-align: center;
  transition: all .5s ease-out;
  &:hover,
  &:focus,
  &:active {
    transition: all .5s ease-out;
    text-decoration: none;
  }
  @include media-breakpoint-up(lg) {
    display: initial;
    width: auto;
    padding: .7rem 2.3rem;
  }
  @include media-breakpoint-up(xl) {
    display: inline-block;
  }
}
@mixin yellow-button() {
  background: $MinionYellow;
  box-shadow: 0 0 11.8927px rgba(0, 0, 0, 0.15);;
  border: 2px solid transparent;
  color: $YaleBlue;
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    background: linear-gradient(181.06deg, $MinionYellow 5.37%, $Chartreuse 99.1%);
    box-shadow: 0px 0px 8.89px rgba(0, 0, 0, 0.55);
    border: 2px solid transparent;
    color: $YaleBlue;
  }
}
@mixin white-transparent-button() {
  border: 2px solid $white;
  background: none;
  color: $white;
  &:hover,
  &:focus,
  &:active {
    background: rgba(255, 255, 255, 0.4);
    text-decoration: none;
  }
}
@mixin blue-transparent-button() {
  border: 1.58539px solid $SteelBlue;
  background: rgba(255, 255, 255, 0.4);
  color: $SteelBlue;
  &:hover,
  &:focus,
  &:active {
    opacity:.7;
    text-decoration: none;
  }
}
@mixin white-button() {
  background: $white;
  box-shadow: 0px 0px 9.88646px rgba(0, 0, 0, 0.4);
  border-radius: 0.8rem;
  border: 1.58539px solid $white;
  color: $Matterhorn;
  &:hover,
  &:focus,
  &:active {
    background: linear-gradient(
        110.76deg
      , $Normay -1.67%, $Pantone 109.68%);
    border: 1.58539px solid transparent;
    box-shadow: none;
    color: white;
  }
}
@mixin link-classic() {
  color: $Normay;
  text-decoration: underline;
  font-weight: 300;
  @include footer-font-size-big;
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    color: $Normay;
  }
}
@mixin link-unclassic() {
  text-decoration: none;
  color: $Normay;
  font-weight: 300;
  &:hover,
  &:focus,
  &:active,
  &:visited{
    text-decoration: underline;
    color: $Normay;
  }
}
@mixin card-design() {
  box-shadow: 0 1.42852px 34.2845px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(28.5704px);
  border-radius: 40px;
  border: none;
}
