// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// User variables (your own variable overrides)

@import 'custom/_variables.scss';



@import '../../../node_modules/bootstrap/scss/bootstrap.scss';


// User styles (your own styles/overrides)
@import 'custom/mixins';
@import 'custom/_app';
@import 'components/parallax-shape-positions';
@import 'components/main-page';
@import 'components/header';
@import 'components/footer';

@import 'components/latest-article';
@import 'components/main-page-tab';

@import 'components/blue-section';
@import 'components/pricing-accordion';
@import 'components/pricing-columns';
@import 'components/solutions';
@import 'components/tabs';
