.main-page-tab{
  padding-top: 5rem;
  font-size: 1.25rem;
  line-height: 2rem;
  color: $ClassicGrey;
  font-weight: 300;
  text-align: center;
  @include media-breakpoint-up(lg) {
    text-align: left;
  }
  &-buttons {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 1rem;
    @include media-breakpoint-up(lg) {
      margin-top: 0;
    }
  }
  &-view{
    padding-top: 3rem;
    padding-bottom: 3rem;
    text-align: left;
    h2 {
      margin: 0 0 2rem;
      font-size: 2rem;
      @include media-breakpoint-up(xl) {
        font-size: 2.75rem;
      }
    }
  }
  &-row {
    margin-top: 2rem;
    margin-bottom: 2rem;
    @include footer-font-size-big;
    @include media-breakpoint-up(lg) {
      margin-top: 4rem;
      margin-bottom: 4rem;
    }

    .button-link-blue-transparent {
      @include blue-transparent-button;
      font-size: 1rem;
      line-height: 2rem;
      padding: .1rem 3rem;
      border-radius: 8px;
      @include media-breakpoint-up(lg) {
        padding: 0 1rem;
      }
      &:hover {
        background: rgba(255, 255, 255, 1);
        text-decoration: none;
      }
    }
    img {
      max-width: 100%;
      margin: 1rem 0;
    }
  }
  .button-yellow-big {
    @include classic-button-big;
    @include yellow-button;
    font-size: 1rem;
    line-height: 1.7rem;
    margin: 1rem 0;
    display: inline-block;
    min-width: 13rem;
    width: auto;
    @include media-breakpoint-up(lg) {
      margin: 0;
      display: inline-block;
    }
  }
  .button-white {
    @include classic-button-big;
    @include white-button;
    font-size: 1rem;
    line-height: 1.7rem;
    margin: 0;
    display: inline-block;
    min-width: 13rem;
    width: auto;
    @include media-breakpoint-up(lg) {
      display: inline-block;
    }
  }
  &-heading{
    font-size: 1.25rem;
    line-height: 2rem;
    margin-bottom: 1rem;
    @include media-breakpoint-up(md) {
      font-size: 2rem;
      line-height: 2.6rem;
    }
  }
  &-blue-head {
    font-size: .9rem;
    line-height: 1.6rem;
    color: #528EBB;
    font-weight: 600;
    margin-bottom: 0;
    @include media-breakpoint-up(md) {
      font-size: 1.5rem;
      line-height: 2rem;
    }
    @include media-breakpoint-up(lg) {
      margin-bottom: .5rem;
    }
  }

  .nav {
    border: none;
    filter: drop-shadow(0px 0px 6.7781px rgba(black, 0.2));
    border-radius: 8px;
    display: inline-flex;
    background: $white;
    .nav-link {
      font-weight: normal;
      border: none;
      background: none;
      color: $SteelBlue;
      padding-left: 2rem;
      padding-right: 2rem;
      @include footer-font-size-big;
      @include media-breakpoint-up(lg) {
        padding-left: 3rem;
        padding-right: 3rem;
      }
      &.active {
        background: $SteelBlue;
        color: white;
        outline: none;
        box-shadow: none;
      }
    }
  }
}
