.pricing-page-accordion {
  .accordion {
    .card {
      margin-bottom: 1rem;
      background: $white;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      width: 100%;
      background: $AliceBlue;
      color: $ClassicGrey;
      font-weight: 300;
      font-size: .8rem;
      @include media-breakpoint-up(md) {
        font-size: 1.2rem;
      }
      @include media-breakpoint-up(xl) {
        font-size: 1.5rem;
      }
      &-header {
        font-weight: 600;
        border: none;
        position: relative;
        background: $white;
        cursor: pointer;
        opacity: 1;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        padding: 1rem;
        @include media-breakpoint-up(lg) {
          padding: 2rem;
        }
        &:after,
        &:before {
          @include pseudo();
          top: 38%;
          width: 0;
          height: 0;
          border-radius: 12px;
        }
        &.shut {
          &:after,
          &:before {
            border-bottom: 6px solid transparent;
            border-top: 6px solid transparent;
            @include media-breakpoint-up(lg) {
              border-bottom: 12px solid transparent;
              border-top: 12px solid transparent;
            }
          }
          &:after {
            border-left: 6px solid white;
            right: 32px;
            @include media-breakpoint-up(lg) {
              border-left: 12px solid white;
            }
          }

          &:before {
            border-left: 6px solid black;
            right: 30px;
            @include media-breakpoint-up(lg) {
              border-left: 12px solid black;
            }
          }
        }
        &.open {
          &:after,
          &:before {
            right: 27px;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;

            @include media-breakpoint-up(lg) {
              border-left: 12px solid transparent;
              border-right: 12px solid transparent;
            }
          }
          &:after {
            border-top: 6px solid white;
            @include media-breakpoint-up(lg) {
              border-top: 12px solid white;
            }
          }
          &:before {
            top: calc(38% + 2px);
            border-top: 6px solid black;
            @include media-breakpoint-up(lg) {
              border-top: 12px solid black;
            }

          }
        }
      }
      &-body {
        padding: 1rem;
        @include media-breakpoint-up(lg) {
          padding: 2rem;
        }
      }
    }
  }
}
